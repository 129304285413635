import assigneeTableUtils from '@/utils/assigneeTableUtils'

export default function useRoomOccupantsTable() {
  const { commonTableColumns, formatDateEndColumn } = assigneeTableUtils()

  const tableColumns = [
    {
      text: 'employee',
      value: 'lastName',
      sortable: true,
      groupable: false,
      class: 'ps-7 text-uppercase',
    },
    ...commonTableColumns,
  ]

  return {
    tableColumns,
    formatDateEndColumn,
  }
}
