<template>
  <v-card>
    <v-card-title class="d-flex justify-space-between align-center">
      {{ $t('occupants') }}
      <v-btn small color="primary" class="me-3" @click.stop="isAddOccupantDrawerVisible = true">
        <v-icon class="me-2" left>{{ icons.mdiPlus }}</v-icon>
        <span v-t="'addOccupant'" />
      </v-btn>
    </v-card-title>

    <v-data-table
      :headers="tableColumns"
      :items="roomData.occupants"
      :group-by="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES) ? 'group' : null"
      sort-by="lastName"
      :item-key="'id'"
      :hide-default-footer="true"
      :items-per-page="-1"
      disable-pagination
      disable-filtering
      :mobile-breakpoint="0"
    >
      <template #[`top`]>
        <v-dialog v-model="isDeleteDialogVisible" max-width="650px">
          <v-card class="pa-sm-10 pa-3">
            <v-card-title v-t="'deleteOccupant'" class="justify-center text-h5" />
            <v-card-text>
              <v-row>
                <v-col v-t="'confirmationModalUndoneQuestion'" cols="12" class="d-flex align-center justify-center" />
              </v-row>
              <v-row>
                <v-col cols="12" class="d-flex justify-center mt-3">
                  <v-btn v-t="'discard'" outlined class="me-3" :disabled="deleteLoading" @click="discardDelete" />
                  <v-btn :loading="deleteLoading" color="error" @click="confirmDelete">{{ $t('delete') }}</v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-dialog v-model="isUpdateDialogVisible" max-width="650px">
          <v-card v-if="isUpdateDialogVisible" class="user-edit-info pa-sm-10 pa-3">
            <v-card-title v-t="'editOccupant'" class="justify-center text-h5" />
            <v-card-text class="mt-5">
              <v-form
                ref="updateOccupantForm"
                class="multi-col-validation"
                :disabled="updateLoading"
                @submit.prevent="confirmUpdate"
              >
                <v-row>
                  <v-col cols="12">
                    <v-btn-toggle v-model="occupantToUpdate.occupied" mandatory color="primary">
                      <v-btn v-t="'employee'" class="text--primary" left outlined />
                      <v-btn v-t="'occupied'" class="text--primary" right outlined />
                    </v-btn-toggle>
                  </v-col>
                  <v-col v-if="occupantToUpdate.occupied" cols="12">
                    <v-text-field
                      v-model="occupantToUpdate.note"
                      :label="$t('note')"
                      outlined
                      dense
                      maxlength="50"
                      hide-details="auto"
                    />
                  </v-col>
                  <v-col v-else cols="12">
                    <v-autocomplete
                      v-model="occupantToUpdate.employee"
                      :items="employeesChoices"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('employee')"
                      :placeholder="$t('employee')"
                      :rules="[validators.required]"
                    >
                      <template v-if="occupantToUpdate.employee" #append-outer>
                        <v-fade-transition leave-absolute>
                          <v-icon color="primary" @click="goToEmployeeDetailsPage(occupantToUpdate.employee)">{{
                            icons.mdiOpenInNew
                          }}</v-icon>
                        </v-fade-transition>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <v-autocomplete
                      v-model="occupantToUpdate.room"
                      :items="roomChoices"
                      outlined
                      dense
                      hide-details="auto"
                      :label="$t('room')"
                      :placeholder="$t('room')"
                      :rules="[validators.required]"
                    >
                      <template v-if="occupantToUpdate.room" #append-outer>
                        <v-fade-transition leave-absolute>
                          <v-icon color="primary" @click="goToRoomDetailsPage(occupantToUpdate.room)">{{
                            icons.mdiOpenInNew
                          }}</v-icon>
                        </v-fade-transition>
                      </template>
                    </v-autocomplete>
                  </v-col>
                  <v-col cols="12">
                    <upgrade-alert-wrapper>
                      <template #blocked-features>
                        <v-col cols="12">
                          <v-menu
                            close-on-content-click
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          >
                            <template v-slot:activator="activator">
                              <v-text-field
                                v-model="occupantToUpdate.dateStart"
                                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                :label="$t('accommodationDateStart')"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="activator.attrs"
                                hide-details="auto"
                                :rules="[
                                  validators.dateRangeValidator(occupantToUpdate.dateStart, occupantToUpdate.dateEnd),
                                ]"
                                v-on="activator.on"
                              />
                            </template>
                            <v-date-picker
                              v-model="occupantToUpdate.dateStart"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            />
                          </v-menu>
                        </v-col>
                        <v-col cols="12">
                          <v-menu
                            close-on-content-click
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                            :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                          >
                            <template v-slot:activator="activator">
                              <v-text-field
                                v-model="occupantToUpdate.dateEnd"
                                :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                                :label="$t('accommodationDateEnd')"
                                readonly
                                outlined
                                dense
                                clearable
                                v-bind="activator.attrs"
                                hide-details="auto"
                                :rules="[
                                  validators.dateRangeValidator(occupantToUpdate.dateStart, occupantToUpdate.dateEnd),
                                ]"
                                v-on="activator.on"
                              />
                            </template>
                            <v-date-picker
                              v-model="occupantToUpdate.dateEnd"
                              :first-day-of-week="1"
                              :locale="$i18n.locale"
                            />
                          </v-menu>
                        </v-col>
                      </template>
                    </upgrade-alert-wrapper>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" class="d-flex justify-center mt-3">
                    <v-btn v-t="'discard'" outlined color="secondary" class="me-3" @click.prevent="discardUpdate" />
                    <v-btn color="primary" type="submit" :loading="updateLoading">
                      {{ $t('save') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-card-text>
          </v-card>
        </v-dialog>

        <v-navigation-drawer
          v-model="isAddOccupantDrawerVisible"
          temporary
          touchless
          :right="!$vuetify.rtl"
          :width="$vuetify.breakpoint.mdAndUp ? '40%' : '100%'"
          app
        >
          <div class="drawer-header d-flex align-center">
            <span v-t="'addNewOccupants'" class="font-weight-semibold text-base text--primary" />
            <v-spacer></v-spacer>
            <v-btn icon small @click="isAddOccupantDrawerVisible = false">
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>
          <v-form ref="addOccupantForm" @submit.prevent="confirmAdd">
            <v-card-text>
              <v-subheader v-t="'occupantData'" />
              <v-btn-toggle v-model="addOccupantData.occupied" mandatory color="primary" class="mb-3">
                <v-btn v-t="'employee'" class="text--primary" left outlined />
                <v-btn v-t="'occupied'" class="text--primary" right outlined />
              </v-btn-toggle>
              <v-autocomplete
                v-if="!addOccupantData.occupied"
                v-model="addOccupantData.occupants"
                :items="employeesChoices"
                outlined
                chips
                clearable
                deletable-chips
                multiple
                hide-details="auto"
                :label="$t('employee')"
                :placeholder="$t('employee')"
                :rules="[validators.nonEmptyValueValidator]"
                class="mb-3"
              />
              <v-text-field
                v-else
                v-model="addOccupantData.note"
                :label="$t('note')"
                outlined
                dense
                maxlength="50"
                hide-details="auto"
                class="mb-3"
              />
              <upgrade-alert-wrapper :form="false" class="mb-3">
                <template #blocked-features>
                  <v-menu
                    close-on-content-click
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                  >
                    <template v-slot:activator="activator">
                      <v-text-field
                        v-model="addOccupantData.dateStart"
                        :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        :label="$t('accommodationDateStart')"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="activator.attrs"
                        hide-details="auto"
                        :rules="[validators.dateRangeValidator(addOccupantData.dateStart, addOccupantData.dateEnd)]"
                        class="mb-3"
                        v-on="activator.on"
                      />
                    </template>
                    <v-date-picker v-model="addOccupantData.dateStart" :first-day-of-week="1" :locale="$i18n.locale" />
                  </v-menu>
                  <v-menu
                    :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                    close-on-content-click
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="activator">
                      <v-text-field
                        v-model="addOccupantData.dateEnd"
                        :disabled="!$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)"
                        :label="$t('accommodationDateEnd')"
                        readonly
                        outlined
                        dense
                        clearable
                        v-bind="activator.attrs"
                        hide-details="auto"
                        :rules="[validators.dateRangeValidator(addOccupantData.dateStart, addOccupantData.dateEnd)]"
                        class="mb-3"
                        v-on="activator.on"
                      />
                    </template>
                    <v-date-picker v-model="addOccupantData.dateEnd" :first-day-of-week="1" :locale="$i18n.locale" />
                  </v-menu>
                </template>
              </upgrade-alert-wrapper>
            </v-card-text>
            <v-card-actions>
              <v-row class="overwritten-drawer-actions">
                <v-col cols="12" md="6">
                  <v-btn color="primary" class="me-3" type="submit" block :loading="addLoading">
                    {{ $t('add') }}
                  </v-btn>
                </v-col>
                <v-col cols="12" md="6">
                  <v-btn v-t="'discard'" color="secondary" outlined type="reset" block @click="discardAdd" />
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-navigation-drawer>
      </template>

      <template #[`header.lastName`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.dateStart`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.dateEnd`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #[`header.actions`]="{ header }">
        {{ $t(header.text) }}
      </template>

      <template #group.header="{ group, headers, toggle, isOpen }">
        <td :colspan="headers.length">
          <v-icon v-if="isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronUpCircleOutline }}</v-icon>
          <v-icon v-else-if="!isOpen" class="mr-2" @click="toggle">{{ icons.mdiChevronDownCircleOutline }}</v-icon>
          <span v-if="group === 'current'" v-t="'currentGroup'" class="font-weight-semibold" />
          <span v-else-if="group === 'future'" v-t="'futureGroup'" class="font-weight-semibold" />
          <span v-else-if="group === 'past'" v-t="'pastGroup'" class="font-weight-semibold" />
        </td>
      </template>

      <template #[`item.lastName`]="{ item }">
        <div v-if="!item.occupied" class="d-flex align-center ms-3">
          <v-avatar
            :color="item.avatar ? '' : 'primary'"
            :class="item.avatar ? '' : 'v-avatar-light-bg primary--text'"
            size="32"
          >
            <v-img v-if="item.avatar" :src="require(`@/assets/images/avatars/${item.avatar}`)"></v-img>
            <span v-else class="font-weight-medium">{{ avatarText(`${item.firstName} ${item.lastName}`) }}</span>
          </v-avatar>

          <div class="d-flex flex-column ms-3">
            <router-link
              :to="{ name: 'apps-employee-view', params: { id: item.employee } }"
              class="font-weight-semibold text-truncate cursor-pointer text-decoration-none"
            >
              {{ item.firstName }} {{ item.lastName }}
            </router-link>
          </div>
        </div>
        <div v-else class="d-flex align-center ms-3">
          <div class="d-flex flex-column ms-3">
            {{ item.note }}
          </div>
        </div>
      </template>

      <template #[`item.dateStart`]="{ item }">
        {{ formatDateToMonthShort(item.dateStart, false) }}
      </template>

      <template #[`item.dateEnd`]="{ item }">
        {{ formatDateEndColumn(item) }}
      </template>

      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-tooltip bottom>
            <template #activator="tooltipActivator">
              <v-btn
                icon
                small
                color="error"
                v-bind="tooltipActivator.attrs"
                v-on="tooltipActivator.on"
                @click="deleteItem(item.id)"
              >
                <v-icon size="18">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'delete'" />
          </v-tooltip>

          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-btn icon color="primary" small v-bind="attrs" @click="updateOccupant(item)" v-on="on">
                <v-icon size="18">
                  {{ icons.mdiPencilOutline }}
                </v-icon>
              </v-btn>
            </template>
            <span v-t="'edit'" />
          </v-tooltip>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import {
  mdiClose,
  mdiPlus,
  mdiPencilOutline,
  mdiChevronUpCircleOutline,
  mdiChevronDownCircleOutline,
  mdiBellOutline,
  mdiTrashCanOutline,
  mdiFileDocumentOutline,
  mdiOpenInNew,
  mdiChevronRight,
} from '@mdi/js'
import { ref, onMounted } from '@vue/composition-api'

import { avatarText, emptyValueFormatter, formatDateToMonthShort } from '@core/utils/filter'
import { required, dateRangeValidator, nonEmptyValueValidator } from '@core/utils/validation'
import _ from 'lodash'
import { useActions, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import useRoomOccupantsTable from './useRoomOccupantsTable'
import houseUtils from '@/utils/houseUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import UpgradeAlertWrapper from '@/components/UpgradeAlertWrapper.vue'

export default {
  components: { UpgradeAlertWrapper },
  setup(props, { emit }) {
    const { deleteOccupant, updateOccupantDetails, getAllEmployeesChoices, createOccupants } = useActions('staff', [
      'deleteOccupant',
      'updateOccupantDetails',
      'getAllEmployeesChoices',
      'createOccupants',
    ])
    const { roomData } = useState('staff', ['roomData'])

    const employeesChoices = ref([])

    const { router } = useRouter()

    const { formatOccupants } = houseUtils()

    const { tableColumns, formatDateEndColumn } = useRoomOccupantsTable()

    const { fetchRoomChoices, roomChoices } = houseUtils()

    const goToEmployeeDetailsPage = (employeeId) => {
      if (!employeeId) return
      const route = router.resolve({ name: 'apps-employee-view', params: { id: employeeId } })
      window.open(route.href, '_blank')
    }

    const goToRoomDetailsPage = (roomId) => {
      if (!roomId) return
      const route = router.resolve({ name: 'apps-house-room-view', params: { id: roomId } })
      window.open(route.href, '_blank')
    }

    onMounted(async () => {
      employeesChoices.value = await getAllEmployeesChoices()
      await fetchRoomChoices()
    })

    // Add
    const addLoading = ref(false)
    const addOccupantForm = ref(null)
    const addOccupantData = ref({
      occupants: [],
      dateStart: null,
      dateEnd: null,
      occupied: false,
      note: '',
    })
    const isAddOccupantDrawerVisible = ref(false)

    const confirmAdd = async () => {
      const isFormValid = addOccupantForm.value.validate()
      if (!isFormValid) return

      addLoading.value = true

      const { success } = await createOccupants(
        formatOccupants(addOccupantData.value).map((occupant) => ({ ...occupant, room: roomData.value.id })),
      )
      if (success) {
        emit('refetch-data')
        isAddOccupantDrawerVisible.value = false
        addOccupantForm.value.reset()
        addOccupantData.value = { occupants: [], occupied: 0, note: '', dateStart: null, dateEnd: null }
      }
      addLoading.value = false
    }
    const discardAdd = () => {
      isAddOccupantDrawerVisible.value = false
      addOccupantForm.value.reset()
      addOccupantData.value = {
        occupants: [],
        dateStart: null,
        dateEnd: null,
      }
    }

    // Delete
    const deleteLoading = ref(false)
    const itemToDeleteId = ref(null)
    const isDeleteDialogVisible = ref(false)

    const deleteItem = (itemId) => {
      itemToDeleteId.value = itemId
      isDeleteDialogVisible.value = true
    }
    const confirmDelete = async () => {
      if (itemToDeleteId.value === null) return

      deleteLoading.value = true

      const { success } = await deleteOccupant({
        occupantId: itemToDeleteId.value,
      })
      if (success) {
        emit('refetch-data')
        itemToDeleteId.value = null
      }
      deleteLoading.value = false
      isDeleteDialogVisible.value = false
    }
    const discardDelete = () => {
      itemToDeleteId.value = null
      isDeleteDialogVisible.value = false
    }

    // Update
    const occupantToUpdate = ref(null)
    const updateLoading = ref(false)
    const updateOccupantForm = ref(null)
    const isUpdateDialogVisible = ref(false)

    const updateOccupant = (occupant) => {
      occupantToUpdate.value = _.cloneDeep({ ...occupant, occupied: Number(occupant.occupied) })
      isUpdateDialogVisible.value = true
    }
    const confirmUpdate = async () => {
      const isFormValid = updateOccupantForm.value.validate()
      if (!isFormValid || occupantToUpdate.value === null) return

      updateLoading.value = true

      const { success } = await updateOccupantDetails({
        occupantData: {
          ...occupantToUpdate.value,
          occupied: Boolean(occupantToUpdate.value.occupied),
        },
      })
      if (success) {
        emit('refetch-data')
        occupantToUpdate.value = null
      }
      updateLoading.value = false
      isUpdateDialogVisible.value = false
    }
    const discardUpdate = () => {
      isUpdateDialogVisible.value = false
      occupantToUpdate.value = null
    }

    // ---------------

    return {
      // Add
      confirmAdd,
      discardAdd,
      addLoading,
      isAddOccupantDrawerVisible,
      addOccupantData,
      addOccupantForm,

      // Delete
      deleteItem,
      confirmDelete,
      discardDelete,
      isDeleteDialogVisible,
      deleteLoading,

      // Update
      updateOccupant,
      confirmUpdate,
      discardUpdate,
      occupantToUpdate,
      isUpdateDialogVisible,
      updateLoading,
      updateOccupantForm,

      // ---------------

      employeesChoices,
      roomData,
      roomChoices,
      tableColumns,

      formatDateEndColumn,
      goToEmployeeDetailsPage,
      goToRoomDetailsPage,

      emit,
      avatarText,
      emptyValueFormatter,
      formatDateToMonthShort,

      validators: {
        required,
        dateRangeValidator,
        nonEmptyValueValidator,
      },

      icons: {
        mdiClose,
        mdiPlus,
        mdiPencilOutline,
        mdiChevronUpCircleOutline,
        mdiChevronDownCircleOutline,
        mdiBellOutline,
        mdiTrashCanOutline,
        mdiFileDocumentOutline,

        mdiOpenInNew,
        mdiChevronRight,
      },

      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
