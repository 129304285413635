<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title
          v-touch="{
            left: () =>
              goToNext(roomData.roomIds, roomData.id, loading, {
                name: 'apps-house-room-view',
                param: 'id',
              }),
            right: () =>
              goToPrevious(roomData.roomIds, roomData.id, loading, {
                name: 'apps-house-room-view',
                param: 'id',
              }),
          }"
          class="justify-center flex-column"
        >
          <div class="d-flex justify-space-between align-center w-full mb-2">
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToPrevious(roomData.roomIds, roomData.id) || loading"
              @click="
                goToPrevious(roomData.roomIds, roomData.id, loading, {
                  name: 'apps-house-room-view',
                  param: 'id',
                })
              "
              ><v-icon>{{ icons.mdiChevronLeft }}</v-icon></v-btn
            >
            <span class="text-center">{{ roomData.name }}</span>
            <v-btn
              icon
              color="primary"
              :disabled="!canGoToNext(roomData.roomIds, roomData.id) || loading"
              @click="
                goToNext(roomData.roomIds, roomData.id, loading, {
                  name: 'apps-house-room-view',
                  param: 'id',
                })
              "
            >
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('name') }}:</span>
              <span class="text--secondary">{{ roomData.name }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-1">
              <span>
                <span class="font-weight-medium me-2">{{ $t('accommodationDemand') }}:</span>
                <span class="text--secondary">{{ roomData.demand }}</span>
              </span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('accommodationWorkload') }}:</span>
              <span class="text--secondary"
                >{{ roomData.currentOccupantsCount }}/{{ roomData.demand }} ({{ roomData.occupancyRate }}%)</span
              >
            </v-list-item>

            <v-list-item v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('futureCount') }}:</span>
              <span class="text--secondary">{{ roomData.futureOccupantsCount }} </span>
            </v-list-item>

            <v-list-item v-if="$can(ACTIONS.READ, PRIVATE.SHINE_FEATURES)" dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('pastCount') }}:</span>
              <span class="text--secondary">{{ roomData.pastOccupantsCount }} </span>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-row justify="center">
            <v-col cols="auto">
              <v-dialog v-model="isDeleteDialogOpen" max-width="650px" persistent>
                <template #activator="{ on, attrs }">
                  <v-btn color="error" v-bind="attrs" v-on="on" @click="isDeleteDialogOpen = true">
                    <v-icon class="me-2">{{ icons.mdiTrashCanOutline }}</v-icon>
                    <span v-t="'delete'" />
                  </v-btn>
                </template>
                <v-card class="pa-sm-10 pa-3">
                  <v-card-title v-t="'delete'" class="justify-center text-h5" />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" class="d-flex align-center justify-center">{{
                        $t('confirmationModalUndoneQuestion')
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center mt-3">
                        <v-btn v-t="'discard'" outlined class="me-3" @click="isDeleteDialogOpen = false" />
                        <v-btn v-t="'delete'" color="error" @click="deleteRoomHandler" />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-dialog>
            </v-col>
            <v-col cols="auto">
              <v-btn color="primary" @click="isBioDialogOpen = !isBioDialogOpen">
                <v-icon class="me-2">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <room-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :room-data.sync="roomData"
        :house-choices="houseChoices"
      ></room-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import { mdiTrashCanOutline, mdiPencilOutline, mdiOpenInNew, mdiChevronLeft, mdiChevronRight } from '@mdi/js'
import { ref } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import RoomBioEdit from './RoomBioEdit.vue'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import navigationUtils from '@/utils/navigationUtils'

export default {
  components: {
    RoomBioEdit,
  },
  props: {
    houseChoices: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup() {
    const isBioDialogOpen = ref(false)
    const isDeleteDialogOpen = ref(false)
    const { router } = useRouter()

    const { deleteRoom } = useActions('staff', ['deleteRoom'])
    const { roomData } = useState('staff', ['roomData'])

    const { canGoToNext, goToNext, canGoToPrevious, goToPrevious } = navigationUtils()

    const deleteRoomHandler = async () => {
      const { house } = roomData.value
      await deleteRoom(roomData.value.id)
      await router.push({ name: 'apps-house-view', params: { id: house } })
    }

    return {
      deleteRoomHandler,
      canGoToNext,
      goToNext,
      canGoToPrevious,
      goToPrevious,

      roomData,
      isBioDialogOpen,
      isDeleteDialogOpen,
      icons: {
        mdiPencilOutline,
        mdiTrashCanOutline,
        mdiOpenInNew,
        mdiChevronLeft,
        mdiChevronRight,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
<style lang="scss">
.text--primary-link {
  color: var(--v-primary-base);
}
</style>
