<template>
  <div v-if="!loading" id="user-view">
    <v-row>
      <v-col cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            houseName: getCurrentHouseName,
            houseId: roomData.house,
            roomName: roomData.name,
          }"
        />
        <room-bio-panel :loading="loading" :house-choices="houseChoices" />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <room-occupants-table v-if="roomData.id || !loading" @refetch-data="fetchRoomData" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mdiChevronLeft } from '@mdi/js'
import { onMounted, onUnmounted, ref, computed, watch } from '@vue/composition-api'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import { useRouter } from '@core/utils'
import router from '@/router'
// eslint-disable-next-line object-curly-newline
import RoomBioPanel from './room-bio-panel/RoomBioPanel.vue'
import RoomOccupantsTable from './room-occupants/RoomOccupantsTable.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'

export default {
  components: {
    BreadCrumbs,
    RoomBioPanel,
    RoomOccupantsTable,
  },
  setup() {
    const houseChoices = ref([])
    const loading = ref(false)

    const { getRoomDetails, getHouseChoices } = useActions('staff', ['getRoomDetails', 'getHouseChoices'])
    const { roomData } = useState('staff', ['roomData'])
    const { SET_POSITION_DATA } = useMutations('staff', ['SET_POSITION_DATA'])

    const fetchRoomData = async () => {
      loading.value = true
      await getRoomDetails(router.currentRoute.params.id)
      loading.value = false
    }

    const { route } = useRouter()

    watch(
      () => route.value.params.id,
      async () => {
        await fetchRoomData()
      },
    )

    const getCurrentHouseName = computed(() => {
      return houseChoices.value.find((house) => house.value === roomData.value.house)?.text
    })

    onMounted(async () => {
      await fetchRoomData()
      houseChoices.value = await getHouseChoices()
    })

    onUnmounted(() => {
      SET_POSITION_DATA({})
    })

    return {
      fetchRoomData,

      getCurrentHouseName,

      roomData,
      houseChoices,
      loading,
      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
