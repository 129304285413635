var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"user-bio-panel"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"pt-10"},[_c('v-card-title',{directives:[{name:"touch",rawName:"v-touch",value:({
          left: function () { return _vm.goToNext(_vm.roomData.roomIds, _vm.roomData.id, _vm.loading, {
              name: 'apps-house-room-view',
              param: 'id',
            }); },
          right: function () { return _vm.goToPrevious(_vm.roomData.roomIds, _vm.roomData.id, _vm.loading, {
              name: 'apps-house-room-view',
              param: 'id',
            }); },
        }),expression:"{\n          left: () =>\n            goToNext(roomData.roomIds, roomData.id, loading, {\n              name: 'apps-house-room-view',\n              param: 'id',\n            }),\n          right: () =>\n            goToPrevious(roomData.roomIds, roomData.id, loading, {\n              name: 'apps-house-room-view',\n              param: 'id',\n            }),\n        }"}],staticClass:"justify-center flex-column"},[_c('div',{staticClass:"d-flex justify-space-between align-center w-full mb-2"},[_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":!_vm.canGoToPrevious(_vm.roomData.roomIds, _vm.roomData.id) || _vm.loading},on:{"click":function($event){return _vm.goToPrevious(_vm.roomData.roomIds, _vm.roomData.id, _vm.loading, {
                name: 'apps-house-room-view',
                param: 'id',
              })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChevronLeft))])],1),_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.roomData.name))]),_c('v-btn',{attrs:{"icon":"","color":"primary","disabled":!_vm.canGoToNext(_vm.roomData.roomIds, _vm.roomData.id) || _vm.loading},on:{"click":function($event){return _vm.goToNext(_vm.roomData.roomIds, _vm.roomData.id, _vm.loading, {
                name: 'apps-house-room-view',
                param: 'id',
              })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChevronRight))])],1)],1)]),_c('v-card-text',[_c('h2',{directives:[{name:"t",rawName:"v-t",value:('details'),expression:"'details'"}],staticClass:"text-xl font-weight-semibold mb-2"}),_c('v-divider'),_c('v-list',[_c('v-list-item',{staticClass:"px-0 my-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v(_vm._s(_vm.$t('name'))+":")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.roomData.name))])]),_c('v-list-item',{staticClass:"px-0 my-1",attrs:{"dense":""}},[_c('span',[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v(_vm._s(_vm.$t('accommodationDemand'))+":")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.roomData.demand))])])]),_c('v-list-item',{staticClass:"px-0 my-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v(_vm._s(_vm.$t('accommodationWorkload'))+":")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.roomData.currentOccupantsCount)+"/"+_vm._s(_vm.roomData.demand)+" ("+_vm._s(_vm.roomData.occupancyRate)+"%)")])]),(_vm.$can(_vm.ACTIONS.READ, _vm.PRIVATE.SHINE_FEATURES))?_c('v-list-item',{staticClass:"px-0 my-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v(_vm._s(_vm.$t('futureCount'))+":")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.roomData.futureOccupantsCount)+" ")])]):_vm._e(),(_vm.$can(_vm.ACTIONS.READ, _vm.PRIVATE.SHINE_FEATURES))?_c('v-list-item',{staticClass:"px-0 my-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v(_vm._s(_vm.$t('pastCount'))+":")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.roomData.pastOccupantsCount)+" ")])]):_vm._e()],1)],1),_c('v-card-actions',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('v-dialog',{attrs:{"max-width":"650px","persistent":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error"},on:{"click":function($event){_vm.isDeleteDialogOpen = true}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.icons.mdiTrashCanOutline))]),_c('span',{directives:[{name:"t",rawName:"v-t",value:('delete'),expression:"'delete'"}]})],1)]}}]),model:{value:(_vm.isDeleteDialogOpen),callback:function ($$v) {_vm.isDeleteDialogOpen=$$v},expression:"isDeleteDialogOpen"}},[_c('v-card',{staticClass:"pa-sm-10 pa-3"},[_c('v-card-title',{directives:[{name:"t",rawName:"v-t",value:('delete'),expression:"'delete'"}],staticClass:"justify-center text-h5"}),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"d-flex align-center justify-center",attrs:{"cols":"12"}},[_vm._v(_vm._s(_vm.$t('confirmationModalUndoneQuestion')))])],1),_c('v-row',[_c('v-col',{staticClass:"d-flex justify-center mt-3",attrs:{"cols":"12"}},[_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('discard'),expression:"'discard'"}],staticClass:"me-3",attrs:{"outlined":""},on:{"click":function($event){_vm.isDeleteDialogOpen = false}}}),_c('v-btn',{directives:[{name:"t",rawName:"v-t",value:('delete'),expression:"'delete'"}],attrs:{"color":"error"},on:{"click":_vm.deleteRoomHandler}})],1)],1)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.isBioDialogOpen = !_vm.isBioDialogOpen}}},[_c('v-icon',{staticClass:"me-2"},[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))]),_c('span',{directives:[{name:"t",rawName:"v-t",value:('edit'),expression:"'edit'"}]})],1)],1)],1)],1)],1),_c('room-bio-edit',{attrs:{"is-bio-dialog-open":_vm.isBioDialogOpen,"room-data":_vm.roomData,"house-choices":_vm.houseChoices},on:{"update:isBioDialogOpen":function($event){_vm.isBioDialogOpen=$event},"update:is-bio-dialog-open":function($event){_vm.isBioDialogOpen=$event},"update:roomData":function($event){_vm.roomData=$event},"update:room-data":function($event){_vm.roomData=$event}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }