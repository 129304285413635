import { ref } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'

export default function houseUtils() {
  const statusChoices = ref([])
  const roomChoices = ref([])

  const { getHouseStatusChoices, getHousesFileTypes, getHouseTags, getRoomChoices } = useActions('staff', [
    'getHouseStatusChoices',
    'getHousesFileTypes',
    'getHouseTags',
    'getRoomChoices',
  ])

  const formatOccupants = (room) => {
    const occupants = []
    if (room.occupied) {
      occupants.push({
        employee: null,
        dateStart: room.dateStart,
        dateEnd: room.dateEnd,
        note: room.note,
        occupied: Boolean(room.occupied),
      })
    } else {
      console.log(room)
      occupants.push(
        ...room.occupants.map((occupant) => ({
          employee: occupant,
          dateStart: room.dateStart,
          dateEnd: room.dateEnd,
          note: room.note,
          occupied: Boolean(occupant.occupied),
        })),
      )
    }

    return occupants
  }

  const fetchStatusChoices = async () => {
    statusChoices.value = await getHouseStatusChoices()
  }

  const fileTypes = ref([])
  const fetchFileTypes = async () => {
    fileTypes.value = await getHousesFileTypes()
  }

  const tagsChoices = ref([])
  const fetchTagChoices = async () => {
    tagsChoices.value = await getHouseTags()
  }

  const fetchRoomChoices = async () => {
    roomChoices.value = await getRoomChoices()
  }

  return {
    formatOccupants,

    fetchStatusChoices,
    statusChoices,

    fetchFileTypes,
    fileTypes,

    tagsChoices,
    fetchTagChoices,

    fetchRoomChoices,
    roomChoices,
  }
}
